import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksisunits', config);
}

const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksisunits/' + code);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksisunits/', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/yoksisunits/' + id, formData);
}

const deleteYoksis = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/yoksisunits/' + code);
}

const studentSearchExcelExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/student-search-excel-export', config);
}

const undergraduateListExcel = async (year) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/undergraduate-list-excel-export/'+year)
}

const educationInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/education-information/'+nationalId)
}

const graduateInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/graduate-information/'+nationalId)
}

const kykInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/kyk-information/'+nationalId)
}

const asalInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/asal-information/'+nationalId)
}

const prepInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/prep-information/'+nationalId)
}

const mebInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/meb-graduate-information/'+nationalId)
}

const equivalenceInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/equivalence-information/'+nationalId)
}

const sentInformation = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/yoksis-sent-information/'+nationalId)
}

const prepDetailList = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/prep-detail-list-excel-export/'+nationalId)
}

const pedagogicList = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/pedagogic-formation-list-excel-export/'+nationalId)
}

const leaveOfAbsenceList = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/leave-of-absence-list-excel-export/'+nationalId)
}

const punishmentList = async (nationalId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/punishment-list-excel-export/'+nationalId)
}

const studentList = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/student-list-excel-export', formData)
}

const otherUniversityStudentsList = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/other-university-students-excel-export', formData)
}

const bulkMebGraduateList = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/bulk-meb-graduate-information-excel-export', formData)
}

const yoksisUniversities = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/universities', config);
}

const yoksisUniversityUnits = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unit/informations', config);
}

const yoksisUnitsUpdate = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/update-university-and-units');
}
const multipleActionExcel = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/bulk-yoksis-graduate-information-excel-export', formData)
}

const yoksisBulkAction = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/yoksis/service/bulk-action", formData);
};

const deleteFromYoksis = async (nationalId, unitId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/student/programs/yoksis-delete-student/' + nationalId + '/' + unitId);
}

const deleteFromYoksisByYoksisId = async (yoksisStudentId, unitId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/student/programs/yoksis-delete-student-by-yoksis-id/' + yoksisStudentId + '/' + unitId);
}

const getConfirmationDatetime = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/last-confirmation-date');
}
const exportExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksisunits/excel-export',{...config,responseType:'arraybuffer'});
}

const addPermit = async (formData, studentProgramId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/yoksis/service/student-program-add-permit/"+studentProgramId, formData);
}

const permitList = (studentProgramId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/student-program-permit-list/'+studentProgramId);
}

const permitDelete = (permitId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/yoksis/service/delete-permit/'+permitId);
}

const bulkAddPermitExcel = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/bulk-add-permit', formData);
}
const yokReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/yoksis/service/permit/excel-export", { responseType: "arraybuffer" });
};

const yokPreparatoryList = (nationalId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/yoksis-prep-list/'+nationalId);
}

const yokPreparatoryDelete = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/student/programs/yoksis-prep-delete/'+ id);
}

const yokPreparatoryUpdate = (studentProgramId, formData)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/student/programs/send-prep-info/'+ studentProgramId, formData);
}

const bulkDeletePermitImport = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/yoksis/service/bulk-delete-permit', formData);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteYoksis,
    studentSearchExcelExport,
    undergraduateListExcel,
    educationInformation,
    graduateInformation,
    kykInformation,
    asalInformation,
    mebInformation,
    equivalenceInformation,
    sentInformation,
    prepDetailList,
    pedagogicList,
    leaveOfAbsenceList,
    punishmentList,
    studentList,
    otherUniversityStudentsList,
    bulkMebGraduateList,
    yoksisUniversities,
    yoksisUniversityUnits,
    yoksisUnitsUpdate,
    multipleActionExcel,
    yoksisBulkAction,
    deleteFromYoksis,
    prepInformation,
    deleteFromYoksisByYoksisId,
    getConfirmationDatetime,
    exportExcel,
    addPermit,
    permitList,
    permitDelete,
    bulkAddPermitExcel,
    yokReport,
    yokPreparatoryList,
    yokPreparatoryDelete,
    yokPreparatoryUpdate,
    bulkDeletePermitImport
};
