<template>
    <div>
        <ValidationObserver ref="filterForm">
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import SectionService from '@/services/SectionService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            filter: {},
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                SectionService.thesisAndProjectsAdvisorReport()
                    .then((response) => {
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
